<template>
    <div class="container pt-5 pb-2">
        <div class="card p-3">
            <h3 class="mb-3">HATIBARI (হাটিবাড়ি)</h3>
            
            <p>
                HATIBARI (হাটিবাড়ি) - A reputed online-based e-commerce website in Bangladesh. The journey of HATIBARI started with the goal that every person can easily shop for their daily essentials at home or office amid various busy schedules. With the slogan “SAVE TIME AND MONEY WITH ORDER” HATIBARI is engaged in trying to free the citizens from harassment in their daily life shopping.
            </p>

            <p>
                Since the beginning of the journey, HATIBARI is constantly trying in various ways to ensure that there is no error in the service. HATIBARI believes that in the current context of Bangladesh, the use of technology can bring the country to the peak of development, and therefore HATIBARI dreams that their efforts can be enjoyed by every person in this country.
            </p>

            <p>
                We are a growing service organization; Any suggestion for improvement is valuable to us. For any kind of suggestion or inquiry contact hatibarishop@gmail.com.
            </p>
        </div>
    </div>
</template>