<template>
    <div class="container-fluid px-0 mt-4 border-top">
        <div class="container pt-5 pb-3 text-body-secondary">
            <div class="row">
                <div class="col-lg-4 pb-4">
                    <router-link class="d-inline-flex align-items-center mb-3 text-body-emphasis text-decoration-none" to="/"
                        aria-label="Bootstrap">
                        <img src="../../assets/logo.png" height="40" alt="Logo" />
                    </router-link>
                    <ul class="list-unstyled m-0">
                        <li class="mb-2 text-justify">
                            HATIBARI (হাটিবাড়ি) - A reputed online-based e-commerce website in Bangladesh. With the slogan “SAVE TIME AND MONEY WITH ORDER” HATIBARI is engaged in trying to free the citizens from harassment in their daily life shopping.
                        </li>
                    </ul>
                    <p class="m-0 mt-3">© Copyright hatibari {{ year }}</p>
                </div>
                <div class="col-lg-2 offset-lg-1 pb-4">
                    <h5>Links</h5>
                    <ul class="list-unstyled m-0 links">
                        <li class="mb-1"><router-link to="/">Home</router-link></li>
                        <li class="mb-1"><router-link to="/about">About</router-link></li>
                        <!-- <li class="mb-1"><router-link to="/contact">Contact</router-link></li> -->
                    </ul>
                </div>
                <div class="col-lg-2 pb-4">
                    <h5>Follow Us</h5>
                    <ul class="list-unstyled m-0 links">
                        <li class="mb-1"><a target="_blank" href="https://www.facebook.com/hatibarishop"><i class="bi bi-facebook me-1"></i> Facebook</a></li>
                        <li class="mb-1"><a target="_blank" href="https://www.youtube.com/@hatibari"><i class="bi bi-youtube me-1"></i> Youtube</a></li>
                        <li class="mb-1"><a target="_blank" href="https://www.instagram.com/hatibarishop/"> <i class="bi bi-instagram me-1"></i> Instagram</a></li>
                        <li class="mb-1"><a target="_blank" href="https://www.tiktok.com/@hatibarishop"> <i class="bi bi-tiktok"></i> Tiktok</a></li>
                        <li class="mb-1"><a target="_blank" href="https://www.pinterest.com/hatibarishop/"> <i class="bi bi-pinterest"></i> Pinterest</a></li>
                    </ul>
                </div>
                <div class="col-12 col-lg-3 pb-4">
                    <h5>Contact Us</h5>
                    <div>Gmail: hatibarishop@gmail.com</div>
                    <!-- <div class="mt-1">© Copyright hatibari 2024</div> -->
                    <!-- <ul class="list-unstyled m-0">
                        <li class="mb-2"><a @click.prevent href="#" target="_blank" rel="noopener">Facebook</a></li>
                        <li class="mb-2"><a @click.prevent href="#" target="_blank" rel="noopener">Youtube</a></li>
                        <li class="mb-2"><a @click.prevent href="#" target="_blank" rel="noopener">Instagram</a></li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    setup() {
        const year = new Date().getFullYear();
        return { year };
    }
};
</script>

<style lang="scss" scope>
@import "src/_preset.scss";

.container-fluid {
    background-color: $cs-white !important;
    * {
        color: $cs-black !important;
    }
    .links {
        a {
            color: $cs-link !important;
            text-decoration: none;
            .bi {
                color: $cs-link !important;
            }
        }
        a:hover {
            color: $cs-secondary !important;
        }

        a:hover .bi{
            color: $cs-secondary !important;
        }

    }
}
</style>