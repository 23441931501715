<template>
    <div class="container">
        <Banner />
        <Listings />
    </div>
</template>

<script>
import Banner from "./Banner.vue"
import Listings from "@/components/listing/Listings.vue";

export default {
    components: {
        Banner,
        Listings
    },
    setup() {
        return {
            
        };
    }
};
</script>
