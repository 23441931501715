import { createRouter, createWebHistory } from 'vue-router'
import Login from '@/components/auth/Login.vue'
import Home from '@/components/home/Home.vue'
import ListingDetails from "@/components/listing/ListingDetails.vue";
import Order from "@/components/order/Order.vue";
import OrderSuccess from '@/components/order/OrderSuccess.vue';
import About from '@/components/home/About.vue';
import OrderConfirm from '@/components/order/OrderConfirm.vue';

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        component: About
    },
    {
        path: '/listing/:id/:title',
        name: 'Listing details',
        component: ListingDetails
    },
    {
        path: '/order/:id',
        name: 'Order',
        component: Order
    },
    {
        path: '/order-confirm',
        name: 'Order confirm',
        component: OrderConfirm
    },
    {
        path: '/order-success',
        name: 'Order success',
        component: OrderSuccess
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
