<template>
    <div class="container mb-5 mt-3">
        <template v-for="(item, index) in listings" :key="index">
            <template v-if="listingId == item.docId">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                        <li class="breadcrumb-item" aria-current="page">
                            <router-link :to="'/listing/' + item.docId + '/' + convertToHyphenAndLowercase(item.title)+'?q=1'">{{ item.title }}</router-link>
                        </li>
                        <li class="breadcrumb-item active">Order</li>
                    </ol>
                </nav>

                <div class="listing-details p-3 mb-4">
                    <div class="d-none d-md-block">
                        <h1 class="order-details mb-3">Order details</h1>
                        <table class="w-100">
                            <tr>
                                <th></th>
                                <th class="px-3">Title</th>
                                <th>Quantity</th>
                                <th class="text-end">Price</th>
                                <th class="text-end">Total Price</th>
                            </tr>
                            <tr>
                                <td class="image">
                                    <img class="w-100" :src="item.photos[0].source" :alt="item.photos[0].altText">
                                </td>
                                <td class="title w-50 px-3">
                                    {{ item.title }}
                                </td>
                                <td class="quantity">
                                    <select v-model="quantity" class="form-select form-select-lg rounded-0" id="quantity">
                                        <option v-for="(quantityItem, quantityIndex) in item.quantity"
                                            :key="quantityIndex" :value="quantityItem">{{ quantityItem }}</option>
                                    </select>
                                </td>
                                <td class="price text-end ps-3">
                                    <span v-if="item.discount">৳{{ item.price - item.discount }}</span>
                                    <span v-else>৳{{ item.price }}</span>
                                </td>
                                <td class="price text-end ps-3">
                                    <span v-if="item.discount">৳{{ (item.price - item.discount)*quantity }}</span>
                                    <span v-else>৳{{ item.price*quantity }}</span>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="d-block d-md-none">
                        <h1 class="order-details mb-3">Order details</h1>
                        <div class="image mb-2">
                            <img class="w-100" :src="item.photos[0].source" :alt="item.photos[0].altText">
                        </div>

                        <p>{{ item.title }}</p>

                        <div class="mb-2">
                            <label><b>Quantity:</b></label>
                            <select v-model="quantity" class="form-select form-select-lg rounded-0" id="quantity">
                                <option v-for="(quantityItem, quantityIndex) in item.quantity" :key="quantityIndex"
                                    :value="quantityItem">{{ quantityItem }}</option>
                            </select>
                        </div>

                        <div class="mb-2" v-if="item.discount"><b>Price:</b> ৳{{ item.price - item.discount }}</div>
                        <div class="mb-2" v-else><b>Price:</b> ৳{{ item.price }}</div>

                        <div v-if="item.discount"><b>Total Price:</b> ৳{{ (item.price - item.discount)*quantity }}</div>
                        <div v-else><b>Total Price:</b> ৳{{ item.price*quantity }}</div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-lg-8 pb-2">
                        <div class="shipping-address p-3">
                            <h1 class="title mb-3">Shipping address</h1>

                            <div class="your-address">Your address</div>
                            <hr class="mt-1">
                            <div class="mb-3">
                                <label for="name" class="form-label">Name <span>*</span></label>
                                <input @click="validationFields.name=false" v-model="name" type="text" class="form-control form-control-lg rounded-0" id="name" placeholder="Enter name">
                                <div v-if="validationFields.name" class="text-danger mt-1">Name is required.</div>
                            </div>
                            <div class="mb-3">
                                <label for="phone" class="form-label">Phone number <span>*</span></label>
                                <input @click="validationFields.phoneNumber=false" v-model="phoneNumber" type="text" class="form-control form-control-lg rounded-0" id="phone" placeholder="Enter phone number">
                                <div v-if="validationFields.phoneNumber" class="text-danger mt-1">Phone number is required.</div>
                            </div>
                            <div class="mb-3">
                                <label for="address" class="form-label">Address (গ্রাম/এলাকা, থানা, জেলা) <span>*</span></label>
                                <textarea @click="validationFields.address=false" v-model="address" class="form-control form-control-lg rounded-0" id="address" rows="3" placeholder="Enter address"></textarea>
                                <div v-if="validationFields.address" class="text-danger mt-1">Address is required.</div>
                            </div>

                            <div v-if="!orderAsAGift" class="mb-3">
                                <label for="shipping-area" class="form-label">Shipping area <span>*</span></label>
                                <select @change="changeShippingCost" v-model="shippingCost" class="form-select form-select-lg rounded-0" id="shipping-area">
                                    <option value="0">Choose one</option>
                                    <option :value="item.inDhaka">In dhaka (Shipping cost ৳{{ item.inDhaka }})</option>
                                    <option :value="item.outOfDhaka">Out of dhaka (Shipping cost ৳{{ item.outOfDhaka }})</option>
                                </select>
                                <div v-if="validationFields.shippingCost" class="text-danger mt-1">Shipping area is required.</div>
                            </div>
                            
                            <div class="form-check">
                                <input v-model="orderAsAGift" class="form-check-input rounded-0" type="checkbox" id="order-as-a-gift">
                                <label class="form-check-label" for="order-as-a-gift">
                                    Order as a gift
                                </label>
                            </div>
                            
                            <div v-if="orderAsAGift" class="mt-3">
                                <div class="your-address">Address of the person you want to send the gift</div>
                                <hr class="mt-1">
                                <div class="mb-3">
                                    <label for="name-gift" class="form-label">Name <span>*</span></label>
                                    <input @click="validationFields.nameGift=false" v-model="nameGift" type="text" class="form-control form-control-lg rounded-0" id="name-gift" placeholder="Enter name">
                                    <div v-if="validationFields.nameGift" class="text-danger mt-1">Name is required.</div>
                                </div>
                                <div class="mb-3">
                                    <label for="phone-gift" class="form-label">Phone number <span>*</span></label>
                                    <input @click="validationFields.phoneNumberGift=false" v-model="phoneNumberGift" type="text" class="form-control form-control-lg rounded-0" id="phone-gift" placeholder="Enter phone number">
                                    <div v-if="validationFields.phoneNumberGift" class="text-danger mt-1">Phone number is required.</div>
                                </div>
                                <div class="mb-3">
                                    <label for="address-gift" class="form-label">Address (গ্রাম/এলাকা, থানা, জেলা) <span>*</span></label>
                                    <textarea @click="validationFields.addressGift=false" v-model="addressGift" class="form-control form-control-lg rounded-0" id="address-gift" rows="3" placeholder="Enter address"></textarea>
                                    <div v-if="validationFields.addressGift" class="text-danger mt-1">Address is required.</div>
                                </div>
                                <div v-if="orderAsAGift" class="mb-3">
                                    <label for="shipping-area" class="form-label">Shipping area <span>*</span></label>
                                    <select @change="changeShippingCost" v-model="shippingCost" class="form-select form-select-lg rounded-0" id="shipping-area">
                                        <option value="0">Choose one</option>
                                        <option :value="item.inDhaka">In dhaka (Shipping cost ৳{{ item.inDhaka }})</option>
                                        <option :value="item.outOfDhaka">Out of dhaka (Shipping cost ৳{{ item.outOfDhaka }})</option>
                                    </select>
                                    <div v-if="validationFields.shippingCost" class="text-danger mt-1">Shipping area is required.</div>
                                </div>
                                <div class="mb-1">
                                    <label for="gift-message" class="form-label">Gift message</label>
                                    <textarea v-model="giftMessage" class="form-control form-control-lg rounded-0" id="gift-message" rows="3" placeholder="Enter gift message"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div  class="col-12 col-lg-4 pt-3 pt-lg-0">
                        <div class="summary">
                            <div class="billing-summary p-3">
                                <h1 class="title mb-2">Order Summary</h1>
                            
                                <table class="w-100">
                                    <tr>
                                        <td class="pt-1" >Items total</td>
                                        <td class="pt-1 text-end">
                                            <span v-if="item.discount">৳{{ (item.price - item.discount)*quantity }}</span>
                                            <span v-else>৳{{ item.price*quantity }}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="pt-1 pb-2">Shipping cost</td>
                                        <td class="pt-1 pb-2 text-end">৳{{ parseFloat(shippingCost) }}</td>
                                    </tr>
                                    <tr class="border-top" >
                                        <td class="pt-2">Total payment</td>
                                        <td class="pt-2 text-end">
                                            <span v-if="item.discount">৳{{ ((item.price - item.discount)*quantity)+parseFloat(shippingCost) }}</span>
                                            <span v-else>৳{{ (item.price*quantity)+parseFloat(shippingCost) }}</span>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            
                            <div class="billing-summary p-3 mt-4">
                                <h1 class="title mb-2">Payment</h1>
                            
                                <div v-if="!orderAsAGift" class="alert alert-primary mt-3 mb-0 p-2 rounded-0">
                                    <div class="cash-on-delivery mb-1"><i class="bi bi-check-circle-fill me-1"></i> Cash On Delivery</div>
                                    Pay with cash upon delivery. But to confirm your order you must pay the shipping cost.
                                </div>

                                <div v-if="orderAsAGift" class="alert alert-primary mt-3 mb-0 p-2 rounded-0">
                                    <div class="cash-on-delivery mb-1"><i class="bi bi-check-circle-fill me-1"></i> Bkash / Nagad / Rocket</div>
                                    To confirm your order you must pay items total + shipping cost.
                                </div>
                            </div>
                            
                            <div class="d-grid mt-4">
                                <button v-if="item.quantity > 0" @click="placeYourOrder(item)" class="btn btn-primary btn-lg rounded-0">Place Your Order 
                                    <span class="price-on-button" v-if="item.discount"> (৳{{ ((item.price - item.discount)*quantity)+parseFloat(shippingCost) }})</span>
                                    <span class="price-on-button" v-else> (৳{{ (item.price*quantity)+parseFloat(shippingCost) }})</span>
                                    <span v-if="spinner" class="spinner"></span>
                                </button>
                                <button disabled v-else class="btn btn-danger btn-lg rounded-0">Out of stock</button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed, ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router';
import ListingService from "@/services/ListingService.js"

export default {
    setup() {
        const store = useStore()
        const theRoute = useRoute()
        const router = useRouter()
        const listingId = ref("")
        const listingService = new ListingService()
        const quantity = ref(1)

        const name = ref("")
        const phoneNumber = ref("")
        const address = ref("")
        const shippingCost = ref("0")
        const orderAsAGift = ref(false)
        const nameGift = ref("")
        const phoneNumberGift = ref("")
        const addressGift = ref("")
        const giftMessage = ref("")
        const transectionId = ref("")

        const validationFields = ref({
            name: false,
            phoneNumber: false,
            address: false,
            shippingCost: false,
            nameGift: false,
            phoneNumberGift: false,
            addressGift: false
        })

        const spinner = ref(false)

        const listings = computed(() => store.getters["listings/getItemList"])

        onMounted(() => {
            listingId.value = theRoute.params.id
            quantity.value = parseInt(theRoute.query.q)
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        })

        function convertToHyphenAndLowercase(str) {
            return listingService.convertToHyphenAndLowercase(str)
        }

        function changeShippingCost() {
            validationFields.value.shippingCost = shippingCost.value != '0' ? false : true
        }

        function placeYourOrder(item) {
            let info = {
                listingDocId: item.docId,
                image: item.photos[0].source,
                productTitle: item.title,
                quantity: quantity.value,
                itemPrice: item.discount ? item.price - item.discount : item.price,
                totalPrice: item.discount ? ((item.price - item.discount)*quantity.value) : item.price*quantity.value,
                senderShippingAddress: {
                    name: name.value,
                    phoneNumber: phoneNumber.value,
                    address: address.value
                },
                receiverShippingAddress: {
                    name: nameGift.value,
                    phoneNumber: phoneNumberGift.value,
                    address: addressGift.value,
                    giftMessage: giftMessage.value,
                },
                orderAsAGift: orderAsAGift.value,
                shippingCost: shippingCost.value,
                orderDate: new Date().getTime(),
                orderStatus: 1,
                transectionId: transectionId.value
            }

            validationFields.value.name = name.value ? false : true
            validationFields.value.phoneNumber = phoneNumber.value ? false : true
            validationFields.value.address = address.value ? false : true
            validationFields.value.shippingCost = shippingCost.value != '0' ? false : true
            if (orderAsAGift.value) {
                validationFields.value.nameGift = nameGift.value ? false : true
                validationFields.value.phoneNumberGift = phoneNumberGift.value ? false : true
                validationFields.value.addressGift = addressGift.value ? false : true
            }

            if (orderAsAGift.value) {
                if ((name.value !== '') && (phoneNumber.value !== '') && (address.value !== '') && (nameGift.value !== '') && (phoneNumberGift.value !== '') && (addressGift.value !== '') && (shippingCost.value !== '0')) {
                    spinner.value = true
                    store.dispatch('orders/saveOrder', {info: info, item: item})
                    .then((res)=> {
                        spinner.value = false
                        router.push("/order-confirm")
                    })
                    .catch((err)=>{
                        spinner.value = false
                    })
                }
            }
            else {
                if ((name.value !== '') && (phoneNumber.value !== '') && (address.value !== '') && (shippingCost.value !== '0')) {
                    spinner.value = true
                    store.dispatch('orders/saveOrder', {info: info, item: item})
                    .then((res)=> {
                        spinner.value = false
                        router.push("/order-confirm")
                    })
                    .catch((err)=>{
                        spinner.value = false
                    })
                }
            }
        }

        return {
            spinner,
            quantity,
            name,
            phoneNumber,
            address,
            shippingCost,
            orderAsAGift,
            nameGift,
            phoneNumberGift,
            addressGift,
            giftMessage,
            transectionId,
            listings,
            listingId,
            validationFields,
            changeShippingCost,
            placeYourOrder,
            convertToHyphenAndLowercase
        }
    }
};
</script>

<style lang="scss" scoped>
@import "src/_preset.scss";

.listing-details {
    background-color: $cs-white;
    .image {
        width: 80px;
    }
    .order-details {
        font-size: $cs-h6-font-size;
        color: $cs-secondary;
    }
}

.shipping-address {
    background-color: $cs-white;
    .title {
        font-size: $cs-h6-font-size;
        color: $cs-secondary;
    }
    label {
        font-weight: 600 !important;
        span {
            color: $cs-required;
            font-weight: bolder !important;
        }
    }
    .form-check {
        .form-check-input {
            width: 20px;
            height: 20px;
        }
        label {
            font-weight: normal !important;
            margin-top: 5px;
            margin-left: 5px;
        }
    }
    .your-address {
        font-weight: 600 !important;
    }
}

.summary {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 95px;
    .billing-summary {
        background-color: $cs-white;

        .title {
            font-size: $cs-h6-font-size;
            color: $cs-secondary;
        }
        .cash-on-delivery {
            font-weight: 600;
        }
    }
}

.price-on-button {
    font-size: $cs-normal-font-size-1 !important;
}
</style>