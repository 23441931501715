import { db } from "@/firebase"
import { query, orderBy, startAt, startAfter, collection, getDocs, addDoc, setDoc, doc, limit, onSnapshot, updateDoc, deleteDoc, getCountFromServer, where } from "firebase/firestore";
import { toast } from 'vue3-toastify';

export default ({
    namespaced: true,
    state: {
        
    },
    getters: {
        
    },
    mutations: {
        
    },
    actions: {
        saveOrder({ commit }, info) {
            return new Promise((resolve, reject) => {
                if ((info.info.quantity <= info.item.quantity) && (info.item.quantity > 0)) {
                    addDoc(collection(db, "orders"), info.info)
                    .then((success) => {
                        localStorage.setItem("orderId", success.id)
                        localStorage.setItem("shippingCost", info.info.orderAsAGift ? (info.info.totalPrice + info.info.shippingCost) : info.info.shippingCost)
                        const washingtonRef = doc(db, "listings", info.item.docId)
                        updateDoc(washingtonRef, {
                            quantity: info.item.quantity - info.info.quantity
                        });
                        resolve(true)
                    })
                    .catch((error) => {
                        toast("Something went wrong, please try again later.", {
                            "theme": "colored",
                            "type": "error",
                            "autoClose": 3000,
                            "transition": "flip",
                            "dangerouslyHTMLString": true
                        })
                        reject(false)
                    })
                } else {
                    toast("Not enough items in stock. "+info.item.quantity+" item(s) available.", {
                        "theme": "colored",
                        "type": "error",
                        "autoClose": 3000,
                        "transition": "flip",
                        "dangerouslyHTMLString": true
                    })
                    reject(false)
                }
            })
        },
        confirmOrder({ commit }, info) {
            return new Promise((resolve, reject) => {
                const washingtonRef = doc(db, "orders", info.orderId)
                updateDoc(washingtonRef, {
                    transectionId: info.transectionId
                })
                .then((success) => {
                    resolve(true)
                    localStorage.removeItem("orderId")
                    localStorage.removeItem("shippingCost")
                })
                .catch((error) => {
                    reject(false)
                })
            })
        },
    }
})
