import { db } from "@/firebase"
import { query, orderBy, startAt, startAfter, collection, getDocs, addDoc, setDoc, doc, limit, onSnapshot, updateDoc, deleteDoc, getCountFromServer, where } from "firebase/firestore";
import { toast } from 'vue3-toastify';

export default ({
    namespaced: true,
    state: {
        itemList: [],
        categoryList: [],
        subCategoryList: [],
        groupList: [],
        colorList: [],
        sizeList: [],
        brandList: [],
    },
    getters: {
        getItemList(state) {
            return state.itemList;
        },
        getCategoryList(state) {
            return state.categoryList;
        },
        getSubCategoryList(state) {
            return state.subCategoryList;
        },
        getGroupList(state) {
            return state.groupList;
        },
        getColorList(state) {
            return state.colorList;
        },
        getSizeList(state) {
            return state.sizeList;
        },
        getBrandList(state) {
            return state.brandList;
        },
    },
    mutations: {
        setItemList(state, list) {
            state.itemList = list;
        },
        setCategoryList(state, list) {
            state.categoryList = list;
        },
        setSubCategoryList(state, list) {
            state.subCategoryList = list;
        },
        setGroupList(state, list) {
            state.groupList = list;
        },
        setColorList(state, list) {
            state.colorList = list;
        },
        setSizeList(state, list) {
            state.sizeList = list;
        },
        setBrandList(state, list) {
            state.brandList = list;
        },
    },
    actions: {
        // listing actions
        getItemList({ commit, state }) {
            return new Promise((resolve, reject) => {
                const citiesRef = collection(db, "listings");
                const q = query(citiesRef, orderBy("title"));

                onSnapshot(q, (querySnapshot) => {
                    let list = [];
                    querySnapshot.forEach((doc) => {
                        list.push({
                            docId: doc.id,//done
                            title: doc.data().title,//done
                            description: doc.data().description,//done
                            tags: doc.data().tags,
                            price: doc.data().price,//done
                            discount: doc.data().discount,//done
                            quantity: doc.data().quantity,//done
                            photos: doc.data().photos,//done
                            video: doc.data().video,//done
                            category: doc.data().category,
                            subCategory: doc.data().subCategory,
                            group: doc.data().group,
                            brand: doc.data().brand,
                            colors: doc.data().colors,
                            sizes: doc.data().sizes,
                            materials: doc.data().materials,
                            inDhaka: doc.data().inDhaka,//todo
                            outOfDhaka: doc.data().outOfDhaka,//todo
                            additionalMessage: doc.data().additionalMessage,//todo
                            listingStatus: doc.data().listingStatus,
                            createDate: doc.data().createDate,
                            updateDate: doc.data().updateDate,
                            state: false
                        })
                    });
                    commit("setItemList", list);
                });
            })
        },
        // category actions
        getCategoryList({ commit, state }) {
            return new Promise((resolve, reject) => {
                const citiesRef = collection(db, "categories");
                const q = query(citiesRef, orderBy("category"));

                onSnapshot(q, (querySnapshot) => {
                    let list = [];
                    querySnapshot.forEach((doc) => {
                        list.push({
                            docId: doc.id,
                            category: doc.data().category,
                            editState: true,
                            deleteState: false
                        })
                    });
                    commit("setCategoryList", list);
                });
            })
        },
        // sub category actions
        getSubCategoryList({ commit, state }) {
            return new Promise((resolve, reject) => {
                const citiesRef = collection(db, "subcategories");
                const q = query(citiesRef, orderBy("subCategory"));

                onSnapshot(q, (querySnapshot) => {
                    let list = [];
                    querySnapshot.forEach((doc) => {
                        list.push({
                            docId: doc.id,
                            categoryId: doc.data().categoryId,
                            subCategory: doc.data().subCategory,
                            editState: true,
                            deleteState: false
                        })
                    });
                    commit("setSubCategoryList", list);
                });
            })
        },
        // groups actions
        getGroupList({ commit, state }) {
            return new Promise((resolve, reject) => {
                const citiesRef = collection(db, "groups");
                const q = query(citiesRef, orderBy("group"));

                onSnapshot(q, (querySnapshot) => {
                    let list = [];
                    querySnapshot.forEach((doc) => {
                        list.push({
                            docId: doc.id,
                            categoryId: doc.data().categoryId,
                            subCategoryId: doc.data().subCategoryId,
                            group: doc.data().group,
                            editState: true,
                            deleteState: false
                        })
                    });
                    commit("setGroupList", list);
                });
            })
        },
        // brand actions
        getBrandList({ commit, state }) {
            return new Promise((resolve, reject) => {
                const citiesRef = collection(db, "brands");
                const q = query(citiesRef, orderBy("brand"));

                onSnapshot(q, (querySnapshot) => {
                    let list = [];
                    querySnapshot.forEach((doc) => {
                        list.push({
                            docId: doc.id,
                            brand: doc.data().brand,
                            state: false,
                            editState: true,
                            deleteState: false
                        })
                    });
                    commit("setBrandList", list);
                });
            })
        },
        // color actions
        getColorList({ commit, state }) {
            return new Promise((resolve, reject) => {
                const citiesRef = collection(db, "colors");
                const q = query(citiesRef, orderBy("color"));

                onSnapshot(q, (querySnapshot) => {
                    let list = [];
                    querySnapshot.forEach((doc) => {
                        list.push({
                            docId: doc.id,
                            color: doc.data().color,
                            colorCode: doc.data().colorCode,
                            state: false,
                            editState: true,
                            deleteState: false
                        })
                    });
                    commit("setColorList", list);
                });
            })
        },
        // size actions
        getSizeList({ commit, state }) {
            return new Promise((resolve, reject) => {
                const citiesRef = collection(db, "sizes");
                const q = query(citiesRef, orderBy("size"));

                onSnapshot(q, (querySnapshot) => {
                    let list = [];
                    querySnapshot.forEach((doc) => {
                        list.push({
                            docId: doc.id,
                            size: doc.data().size,
                            state: false,
                            editState: true,
                            deleteState: false
                        })
                    });
                    commit("setSizeList", list);
                });
            })
        },
    }
})
